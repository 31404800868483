@mixin darkTheme() {
  .dark-theme & {
    @content;
  }
}

@mixin lightTheme() {
  .light-theme & {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin ip6Height() {
  @media (max-height: 510px) {
    @content;
  }
}

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  }
}
