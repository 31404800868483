* {
  box-sizing: border-box;
}

body {
  margin: 0;
  // font-family: 'EB Garamond', serif;
  font-family: var(--font-rale-way);

}

p {
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: -webkit-fill-available;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
}

.aspect-ratio {
  width: 100%;
  position: relative;

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

#ReactSimpleImageViewer {
  z-index: 99 !important;
}

p {
  margin: 0;
}

a {
  color: #1890ff;
  text-decoration: none;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination-progressbar-fill {
  background-color: #77a152 !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background-image: url('../assets/bg.png');
  // background-image: url('../assets/bg.webp');
  background-repeat: no-repeat;
  background-size: cover;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mini_calendar {
  border-bottom: 1px solid var(--black-color);
  padding-bottom: 12px;

  .calendar-month {
    text-align: center;
    font-weight: 600;
    color: var(--black-color);
    font-size: 1.25rem;

    @include ip6Height {
      font-size: 16px;
      padding: 0;
      padding-top: 12px;
    }
  }

  .this-day {
    border-radius: 50%;
    border: 2px solid var(--black-color);
    background-color: #F8CBA6;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  table {
    width: 100%;
    border-spacing: 0px;

    th {
      text-align: center;
      padding: 8px 0;
      color: #484747;
      border-top: 1px solid var(--black-color);
      border-bottom: 1px solid var(--black-color);
      font-size: 1rem;
      font-weight: normal;
    }

    td {
      width: 40px;
      height: 40px;
      padding-top: 20px;
      text-align: center;
      vertical-align: middle;
      font-size: 1.15rem;
    }
  }
}

.count-down-boxes {
  display: flex;

  > div {
    width: calc(100% / 4);
    text-align: center;
    display: flex;
    flex-direction: column;

    span {
      font-size: 1rem;
    }

    .date, .over {
      font-family: 'Great Vibes', cursive;
      font-size: 2.5rem;
    }

    @include ip6Height {
      span {
        font-size: 14px;
      }

      .date {
        font-size: 2rem;
      }
    }
  }
}

.box {
  position: relative;
  background-color: var(--white-color);
  padding: 24px 30px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    width: 95%;
    height: 98%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // border: 2px solid #ccc;
    border-radius: 4px;
  }
}

.time-with-line-top-bottom {
  padding: 3px;
  position: relative;

  &::before, &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--black-color);
    left: 0;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.each-accessory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--black-color);
  font-size: 15px;

  &:hover {
    cursor: pointer;
  }
}

.loading {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: #FFFCF8;

  img {
    width: 250px;
  }
}

.modal-introduce {
  .modal-content {
    background-color: unset !important;
    border: none;
  }

  .modal-body {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      transform: translateX(100px);
      max-width: 90px;
      filter: invert(1);
      animation: rotationAnimated 1.1s infinite alternate;
    }
  }
}

@keyframes rotationAnimated {
  from {
    transform: translateX(100px);
  }

  to {
    transform: translateX(0px);
  }
}