.bg-full-screen {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  top: 0;

  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.flower {
  width: 100%;
  height: 100%;
  filter: opacity(0.3);
  object-fit: cover;
}

.screen-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  z-index: 2;

  p {
    color: var(--white-color);
    text-shadow: var(--text-shadow);
  }

  p:nth-of-type(1) {
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 12px;
    letter-spacing: 3px;

    @include ip6Height {
      font-size: 20px;
    }
  }

  p:nth-of-type(2) {
    font-size: 18px;
    letter-spacing: 3px;
    font-family: var(--font-rale-way);
    
    @include ip6Height {
      font-size: 14px;
    }
  }

  .content {
    .main-img {
      width: 40vh;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 1);
      border-radius: 4px;
      margin-bottom: 12px;
    }

    .flower-img {
      max-width: 80px;
      margin: auto;
      img {
        width: 100%;
      }
    }
  }
}

.screen-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;

  .mini_calendar,
  .save-date {
    position: relative;
    z-index: 3;
  }

  .box {
    @include ip6Height {
      width: 120% !important;
      padding: 70px 50px !important;
    }
  }

  .mini_calendar {
    @include ip6Height {
      tr > th {
        font-size: 14px;
      }
      tr > td {
        font-size: 12px;
        padding-top: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .save-date {
    margin-bottom: 14px;
    @include ip6Height {
      max-width: 100px;
      margin-bottom: 8px;
    }
  }

  .flower {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.screen-3 {
  .flower {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .main-text {
    span {
      font-family: Great Vibes, cursive;
      font-size: 2.5rem;
      font-weight: 300;
      display: block;

      &:nth-child(1) {
        text-align: left;
        margin-right: 150px;
      }

      &:nth-child(3) {
        text-align: right;
      }

      @include ip6Height {
        font-size: 1.8rem;
      }
    }
  }

  .sub-text {
    span,
    p {
      font-size: 15px;
      text-transform: uppercase;
    }

    .time-wedding {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .time-wedding-date {
      font-size: 45px;
    }

    .time-wedding-real {
      text-transform: none;
      font-style: italic;
      margin-bottom: 6px;
    }

    @include ip6Height {
      span, p {
        font-size: 13px;
      }
    }
  }

  .family {
    display: flex;
    justify-content: space-between;

    p {
      font-weight: 500;
    }
  }

  .box {
    @include ip6Height {
      transform: translateY(-40px) !important;
    }
  }
}
