//! Variable overrides
:root {
  --main-color: rgba(32, 168, 216, 1);
  --black-color: #252525;
  --white-color: #fff;

  --width: 840px;

  --font-rale-way: 'Raleway', sans-serif;
  --text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.7);
  --animate-delay: 0.5s;
  --animate-duration: 0.5s;
}