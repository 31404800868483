.main-slider {
  width: 100%;
  height: 100vh;
  position: relative;

  // overflow: hidden;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 36, 1);
    opacity: 0.7;
    z-index: 2;
  }

  &__bg-img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;

    @include mobile {
      background-position-x: 80%;
    }
  }

  &__text {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      width: 84px;
      height: 84px;
      transform: translateX(-14px);

      @include mobile {
        transform: translate(0px);
      }
    }

    p {
      color: var(--white-color);
      font-size: 70px;
      margin-bottom: 1.5rem;
    }

    p:nth-of-type(1) {
      letter-spacing: 3px;

      @include mobile {
        font-size: 29px;
      }
    }

    p:nth-of-type(2) {
      font-size: 16px;
      letter-spacing: 3px;
      font-family: var(--font-rale-way);
      position: relative;

      @include mobile {
        font-size: 13px;
        line-height: 1.5;
      }

      &::after,
      &::before {
        content: '';
        width: 35px;
        height: 1px;
        background-color: var(--white-color);
        position: absolute;
        top: 50%;

        @include mobile {
          display: none;
        }
      }

      &::after {
        right: -10px;
      }

      &::before {
        left: -10px;
      }
    }
  }
}

.main-invitation {
  display: flex;
  align-items: center;
  justify-content: center;
  --space: 60px;
  padding-top: calc(var(--space) + 30px);
  padding-bottom: calc(var(--space) + 30px);
  gap: var(--space);

  @include mobile {
    --space: 30px;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  > div {
    width: 555px;
    height: 600px;
    background-color: #fff;
    box-shadow: 0px 2px 4px #ccc;
    border-radius: 4px;
    flex-shrink: 0;

    @include mobile {
      width: 100%;
    }
  }

  &__left,
  &__right {
    position: relative;
    padding: 50px;

    @include mobile {
      padding: 20px;
      height: auto !important;
    }

    &__flower {
      position: absolute;
      user-select: none;
    }

    &__box {
      width: 100%;
      height: 100%;
      border: 3px solid rgba(0, 0, 0, 0.2);
      padding: 16px;
      border-radius: 4px;
    }
  }

  &__left {
    &__flower {
      top: -70px;
      left: -180px;

      @include mobile {
        width: 180px;
        top: -30px;
        left: -75px;
      }
    }

    &__box {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &__save-the-date {
        display: flex;
        justify-content: center;

        img {
          width: 75%;
          filter: invert(1);
          object-fit: cover;
        }
      }

      &__text {
        > p {
          text-align: center;
          font-size: 1.3rem;
        }

        > p:nth-child(1) {
          color: #797979
        }

        > p:nth-child(2) {
          font-size: 1.7rem;
          font-weight: 500;
          margin: 0.5rem 0;
        }
      }
    }
  }

  &__right {
    &__flower {
      max-width: 55%;
      right: -15%;
      bottom: -130px;

      @include mobile {
        width: 210px;
        right: -15px;
        bottom: -50px;
      }
    }
  }
}

.footer {
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  background-image: url('../../assets/footer-shape.png');
  background-repeat: no-repeat;
  background-position: bottom 10% center;
  padding: 16px;
  padding-top: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  background-color: #fff;

  &__text {
    font-size: 1rem;

    @include ip6Height {
      font-size: 0.825rem;
    }
  }

  &__couple {
    width: 400px;
    height: 400px;
    background-image: url('../../assets/couple-frame.png');
    background-repeat: no-repeat;
    background-position: bottom center;
    overflow: hidden;
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    background-size: cover;
    flex-shrink: 0;

    @include mobile {
      width: 50vw;
      height: 50vw;
    }

    @include ip6Height {
      transform: translateY(10px);
    }

    > img:first-child {
      --width-image: 75%;
      width: var(--width-image);
      height: var(--width-image);
      object-fit: cover;

      border-radius: 50%;
      transform: translate(-6px, -18px);
      z-index: 1;
      position: absolute;
      box-shadow: 0px 2px 4px #ccc;
      object-position: 0px -15px;
    }
  }

  &__thank {
    max-width: 280px;
    width: 30vw;
    margin-bottom: 12px;
    filter: invert(1);
  }

  .footer-accessories {
    display: flex;
    gap: 16px;

    > div {
      max-width: 150px;
      max-height: 150px;
      width: calc(100vw / 3 - 20px);
      height: calc(100vw / 3 - 20px);

      @include ip6Height {
        font-size: 12px;
      }
    }
  }

  .footer__phone {
    @include ip6Height {
      > span {
        font-size: 13px;

        > span {
          font-size: 13px !important;
        }
      }
    }
  }
}

.main-character {
  background-color: #fff;
  padding: 60px 0px 80px 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__info {
    display: flex;

    width: 1200px;
    height: 600px;
    box-shadow: 0px 2px 4px #ccc;
    border-radius: 4px;

    img {
      max-width: 50%;
      object-fit: cover;
    }

    &.reverse {
      img { order: 2 } 
    }

    &__details {
      width: 50%;
      padding: 40px;
      background-color: #f9f9f9;

      h2 {
        font-size: 3rem;
      }

      p {
        font-size: 1.25rem;
      }
    }

    @include mobile {
      width: 100%;
      height: auto;
      flex-direction: column;
      img, &__details {
        width: 100%;
        max-width: 100%;
      }

      &.reverse {
        img {
          order: unset !important;
        }
      }

      h2 {
        font-size: 2rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }
}

.main-albums {
  padding: 60px 0;

  &__list{
    max-width: 1024px;
    margin: auto;

    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    > img {
      width: calc(100% / 2 - 5px);
      box-shadow: 0px 2px 4px #ccc;
      border-radius: 4px;
      object-fit: cover;
      transition: .3s;

      &:hover {
        cursor: pointer;
        filter: brightness(0.8);
      }
    }
  }
}